/**
    Font Awesome by Dave Gandy - http://fontawesome.io
*/

$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";

/**
    Bootstrap v4 Navbar Breakpoints
    extra-small(xs) widths <576px
    small (sm) widths <768px
    medium (md) widths <992px
    large (lg) widths <1200px
*/
$xx-tiny: 294px;
$extra-tiny: 305px;
$tiny: 364px;
$xxx-small: 375px;
$xx-small: 423px;
$extra-small: 576px;
$small: 768px;
$medium: 992px;
$large: 1200px;

$navbar-bg: #43bcf1; // was #0e73b9;
$navbar-load-indicator: #0e73b9;  // was #43bcf1;
$navbar-border: #0e73b9;  // was #43bcf1;

$dropdown-menu-font: #333333;
$navbar-dropdown-bg: #ffffff;

$sdm-nav-theme: white;

$body-background: #f3f3f3;

$reports-overview-width: 31.4px;
$reports-navbar-item-hover: #f8f9fa;
$report-color-overview: #9ba90f;
$report-color-overview-table: #43bcf1;
$report-color-active-users: #9ba90f;
$report-color-time-played: #f3c20e;
$report-color-books-read: #f69322;
$report-color-overall-avg: #f69322;
$report-color-phonological: #e7148a;
$report-color-comprehension: #902a8b;
$cheereader-color: #29aae1;
$resource-color: #43bcf1;


$vertical-line-separator-color: #979797;

$table-header-label-font-color: #4a4a4a;
$table-body-cell-label-color: #333333;
$table-active-row-background: #c5eafb;
$table-row-background: #f3f3f3;
$table-active-row-border: #ffffff;

$table-nav-search-color: #716e72; // #d0cfd1;
$table-nav-search-border: #716e72; // #d0cfd1;
$table-nav-search-icon: #716e72; // #d0cfd1;

$previous-level-btn-font-color: #29aae1;
