/* global styles go in this file, also import other style files */
@import './bootstrap';
@import './variables';
@import './mixins';
@import '~ngx-toastr/toastr';

// TODO: body is no longer required; remove?
html {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    background: $body-background !important;
    font-family: Helvetica Neue, Helvetica, Arial, serif, sans-serif;
    font-size: 14px;
    color: #333;
}

.small {
    font-size: 12px;
}

.tooltip-label {
    font-family: Soleil;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
}

.tooltip-val {
    font-family: Soleil;
    font-size: 12px;
    height: 10px;
    font-weight: normal;
    text-align: center;
    color: #ffffff;
}

.cursor-pointer {
    cursor: pointer;
}

*, *:after, *:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

// TODO: generic defaults necessary?
@font-face {
    font-family: Soleil;
    font-style: normal;
    src: url('/assets/font/Soleil.otf');
}

@font-face {
    font-family: Soleil;
    font-style: italic;
    src: url('/assets/font/Soleil-Italic.otf');
}

@font-face{
    font-family: Soleil-Bold;
    font-style: bold;
    src: url('/assets/font/Soleil-Bold.otf');
}

app-navbar {
    #share-to-classroom {
        display: none;
    }

    .is-game {
        #share-to-classroom {
            cursor: pointer;
            display: inline-block;
        }
    }
}

.print-toggle-button {
    display: none;
}

.loading-section {
    padding: 20px !important;
    height: 100% !important;
    width: 100%;
    display: flex !important;

    .loading-div {
        margin: auto;
        align-content: center;

        .loading {
            font-weight: bold;
        }
    }
}

.loading-nav {
    background-color: #f3f3f3;
    font-weight: bold;

    .loading-div-nav {
        padding: 20px;
    }
}

@media not print {
    .print-only {
        display: none !important;
    }
}

@media print {
    app-navbar,
    .reports-header-box,
    .reports-nav-bar,
    app-footer,
    .container:not(.overall-avg-reports-table-section) .row > div:first-child,
    [class^="print-button"],
    ngx-tooltip-content,
    app-d3-tooltip,
    .slider.round,
    .not-print {
        display: none !important;
    }

    body {
        background: #ffffff !important;
    }

    .print-toggle-button {
        display: block;
        margin: 0 auto 0.5rem;
    }

    @page {
        margin: 1.6cm;
    }

    .reports-chart-control-bar {
        height: unset !important;
    }

    .avoid-page-break {
        page-break-inside: avoid;
        display: table;
    }

    * {
        font-family: sans-serif !important;
    }

    .reports-chart-title {
        margin: 0 !important;
    }
}

.modal.fade.my-window-class {
    .modal-dialog {
        margin: 0;
        max-width: none;

        .modal-content {
            height: 100vh;
            margin-left: auto;
            border: none;
            background: none;
        }
    }
}

button {
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.toast-container {
  .ngx-toastr {
    color: #333333;
    font-family: Soleil;
    font-size: 14px;
    background-position: 15px 15px;
    background-size: 28px 32px;
    padding-left: 60px;
    width: 320px;

    &.toast-success {
      background-color: #88c64d;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='28' height='22' viewBox='0 0 28 22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.495 3.037 25.037.52A1.651 1.651 0 0 0 23.81 0c-.483 0-.892.174-1.23.519L10.73 12.685 5.42 7.223a1.652 1.652 0 0 0-1.228-.519 1.65 1.65 0 0 0-1.23.519L.508 9.74A1.736 1.736 0 0 0 0 11.001c0 .493.169.913.507 1.258l6.54 6.704 2.455 2.518c.337.347.747.519 1.228.519.483 0 .892-.173 1.23-.519l2.455-2.518 13.08-13.408C27.83 5.21 28 4.79 28 4.298c0-.496-.169-.916-.505-1.26z' fill='%23333' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    }

    &.toast-warning {
      background-color: #fcb653;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='28' height='32' viewBox='0 0 28 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.17 18.045c-1.088-2.816-1.632-5.783-1.632-8.902 0-1.964-.538-3.524-1.615-4.679-1.077-1.154-2.558-1.851-4.442-2.089a1.7 1.7 0 0 0 .134-.66c0-.477-.157-.882-.47-1.215A1.513 1.513 0 0 0 14 0c-.449 0-.83.167-1.144.5a1.706 1.706 0 0 0-.471 1.214c0 .227.044.447.134.661-1.884.238-3.365.935-4.442 2.09C7 5.618 6.462 7.178 6.462 9.142c0 3.12-.544 6.086-1.633 8.902C3.741 20.86 2.131 23.226 0 25.143c0 .619.213 1.155.64 1.607.426.452.93.679 1.514.679h7.538c0 1.262.42 2.34 1.262 3.232C11.796 31.554 12.811 32 14 32c1.19 0 2.205-.446 3.046-1.34.841-.892 1.262-1.97 1.262-3.231h7.538c.584 0 1.089-.226 1.515-.679.426-.452.639-.988.639-1.607-2.131-1.917-3.74-4.283-4.83-7.098zM14 30.57c-.819 0-1.517-.306-2.095-.919-.578-.613-.867-1.355-.867-2.224 0-.19.09-.285.27-.285.18 0 .269.095.269.285 0 .703.238 1.307.715 1.813S13.338 30 14 30c.18 0 .27.096.27.286 0 .19-.09.285-.27.285z' fill='%23333' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    }

    &.toast-error {
      color: #FFFFFF;
      background-color: #eb0e11;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='28' height='32' viewBox='0 0 28 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.923 5c0 8.958 1.96 21.788-13.923 27C-1.882 26.788.077 13.958.077 5 5.844 5 10.263 3.44 14 0c3.736 3.44 8.155 5 13.923 5zM14.292 20.469c.35 0 .646-.11.889-.331.244-.22.385-.543.423-.966l1.313-9.627c.02-.092.029-.22.029-.386 0-.699-.253-1.25-.758-1.656-.506-.405-1.138-.606-1.896-.606-.759 0-1.391.201-1.896.606-.505.405-.759.947-.759 1.628 0 .166.01.295.03.386l1.283 9.655c.058.423.21.745.453.966.242.22.539.331.889.331zm0 6.18c.72 0 1.312-.226 1.779-.676.467-.451.7-1.016.7-1.697s-.233-1.247-.7-1.697c-.467-.45-1.06-.676-1.78-.676s-1.312.226-1.779.676c-.466.451-.7 1.016-.7 1.697 0 .68.234 1.247.7 1.697.467.45 1.06.675 1.78.675z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
    }

    .toast-title {
      font-family: Soleil-Bold;
    }
  }
}
